import {tns} from "tiny-slider/src/tiny-slider.module";

if (document.querySelector('.slide-wrapper')) {

    var slider = tns({
        container: ".slide-wrapper",
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 1
            }
        },
        autoplay: true,
        autoplayTimeout: 10000,
        mouseDrag: true,
        loop: true,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        nav: true,
        autoplayButton: false,
        controls: false
    });


    if (document.querySelector('.prev')) {
        document.querySelector('.prev').onclick = function () {
            slider.goTo('prev');
        };
    }

    if (document.querySelector('.next')) {

        document.querySelector('.next').onclick = function () {
            slider.goTo('next');
        };
    }
}

if (document.querySelector(".slide-wrapper-produtos")) {
    var sliderPrdutos = tns({
        container: ".slide-wrapper-produtos",
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 1,
            },
            992: {
                items: 3,
            },
            1200: {
                items: 4,
            },
        },
        autoplay: true,
        autoplayTimeout: 15000,
        mouseDrag: true,
        loop: true,
        gutter: 15,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        nav: true,
        autoplayButton: false,
        controls: false,
    });

    if (document.querySelector(".prev-produtos")) {
        document.querySelector(".prev-produtos").onclick = function () {
            sliderPrdutos.goTo("prev");
        };
    }

    if (document.querySelector(".next-produtos")) {
        document.querySelector(".next-produtos").onclick = function () {
            sliderPrdutos.goTo("next");
        };
    }
}


if (document.querySelector(".slide-wrapper-portfolios")) {
    var sliderPortfolios = tns({
        container: ".slide-wrapper-portfolios",
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 1,
            },
            992: {
                items: 3,
            },
            1200: {
                items: 4,
            },
        },
        autoplay: true,
        autoplayTimeout: 15000,
        mouseDrag: true,
        loop: true,
        gutter: 15,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        nav: true,
        autoplayButton: false,
        controls: false,
    });

    if (document.querySelector(".prev-portfolios")) {
        document.querySelector(".prev-portfolios").onclick = function () {
            sliderPortfolios.goTo("prev");
        };
    }

    if (document.querySelector(".next-portfolios")) {
        document.querySelector(".next-portfolios").onclick = function () {
            sliderPortfolios.goTo("next");
        };
    }
}

if (document.querySelector(".slide-wrapper-gallerys")) {
    var sliderGallerys = tns({
        container: ".slide-wrapper-gallerys",
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 1,
            },
            992: {
                items: 3,
            },
            1200: {
                items: 4,
            },
        },
        autoplay: true,
        autoplayTimeout: 15000,
        mouseDrag: true,
        loop: true,
        gutter: 15,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        nav: true,
        autoplayButton: false,
        controls: false,
    });

    if (document.querySelector(".prev-gallerys")) {
        document.querySelector(".prev-gallerys").onclick = function () {
            sliderGallerys.goTo("prev");
        };
    }

    if (document.querySelector(".next-gallerys")) {
        document.querySelector(".next-gallerys").onclick = function () {
            sliderGallerys.goTo("next");
        };
    }
}

if (document.querySelector(".slide-wrapper-servicos")) {
    var sliderServicos = tns({
        container: ".slide-wrapper-servicos",
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 1,
            },
            992: {
                items: 3,
            },
            1200: {
                items: 4,
            },
        },
        autoplay: true,
        autoplayTimeout: 15000,
        mouseDrag: true,
        loop: true,
        gutter: 15,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        nav: true,
        autoplayButton: false,
        controls: false,
    });

    if (document.querySelector(".prev-servicos")) {
        document.querySelector(".prev-servicos").onclick = function () {
            sliderServicos.goTo("prev");
        };
    }

    if (document.querySelector(".next-servicos")) {
        document.querySelector(".next-servicos").onclick = function () {
            sliderServicos.goTo("next");
        };
    }
}

if (document.querySelector(".slide-wrapper-categorias")) {
    var sliderCategorias = tns({
        container: ".slide-wrapper-categorias",
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 2,
            },
        },
        autoplay: true,
        autoplayTimeout: 15000,
        mouseDrag: true,
        loop: true,
        gutter: 15,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        nav: false,
        autoplayButton: false,
        controls: false,
    });

    if (document.querySelector(".prev-categorias")) {
        document.querySelector(".prev-categorias").onclick = function () {
            sliderCategorias.goTo("prev");
        };
    }

    if (document.querySelector(".next-categorias")) {
        document.querySelector(".next-categorias").onclick = function () {
            sliderCategorias.goTo("next");
        };
    }
}

if (document.querySelector(".slide-wrapper-fornecedores")) {
    var sliderFornecedores = tns({
        container: ".slide-wrapper-fornecedores",
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 1,
            },
            992: {
                items: 3,
            },
            1200: {
                items: 4,
            },
        },
        autoplay: true,
        autoplayTimeout: 15000,
        mouseDrag: true,
        loop: true,
        gutter: 75,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        nav: true,
        autoplayButton: false,
        controls: false,
    });

    if (document.querySelector(".prev-fornecedores")) {
        document.querySelector(".prev-fornecedores").onclick = function () {
            sliderFornecedores.goTo("prev");
        };
    }

    if (document.querySelector(".next-fornecedores")) {
        document.querySelector(".next-fornecedores").onclick = function () {
            sliderFornecedores.goTo("next");
        };
    }
}

if (document.querySelector(".slide-wrapper-clientes")) {
    var sliderClientes = tns({
        container: ".slide-wrapper-clientes",
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 1,
            },
            992: {
                items: 3,
            },
            1200: {
                items: 4,
            },
        },
        autoplay: true,
        autoplayTimeout: 15000,
        mouseDrag: true,
        loop: true,
        gutter: 75,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        nav: true,
        autoplayButton: false,
        controls: false,
    });

    if (document.querySelector(".prev-clientes")) {
        document.querySelector(".prev-clientes").onclick = function () {
            sliderClientes.goTo("prev");
        };
    }

    if (document.querySelector(".next-clientes")) {
        document.querySelector(".next-clientes").onclick = function () {
            sliderClientes.goTo("next");
        };
    }
}

if (document.querySelector(".slide-wrapper-patrocinadores")) {
    var sliderPatrocinadores = tns({
        container: ".slide-wrapper-patrocinadores",
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 1,
            },
            992: {
                items: 3,
            },
            1200: {
                items: 4,
            },
        },
        autoplay: true,
        autoplayTimeout: 15000,
        mouseDrag: true,
        loop: true,
        gutter: 75,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        nav: true,
        autoplayButton: false,
        controls: false,
    });

    if (document.querySelector(".prev-patrocinadores")) {
        document.querySelector(".prev-patrocinadores").onclick = function () {
            sliderPatrocinadores.goTo("prev");
        };
    }

    if (document.querySelector(".next-patrocinadores")) {
        document.querySelector(".next-patrocinadores").onclick = function () {
            sliderPatrocinadores.goTo("next");
        };
    }
}

if (document.querySelector(".slide-wrapper-equipe")) {
    var sliderEquipe = tns({
        container: ".slide-wrapper-equipe",
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 1,
            },
            992: {
                items: 3,
            },
            1200: {
                items: 4,
            },
        },
        autoplay: true,
        autoplayTimeout: 15000,
        mouseDrag: true,
        loop: true,
        gutter: 15,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        nav: true,
        autoplayButton: false,
        controls: false,
    });

    if (document.querySelector(".prev-equipe")) {
        document.querySelector(".prev-equipe").onclick = function () {
            sliderEquipe.goTo("prev");
        };
    }

    if (document.querySelector(".next-equipe")) {
        document.querySelector(".next-equipe").onclick = function () {
            sliderEquipe.goTo("next");
        };
    }
}

if (document.querySelector(".slide-wrapper-fotos-sobre")) {
    var sliderFotosSobre = tns({
        container: ".slide-wrapper-fotos-sobre",
        items: 1,
        slideBy: 1,
        autoplay: true,
        autoplayTimeout: 15000,
        loop: true,
        mouseDrag: true,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        nav: false,
        autoplayButton: false,
        controls: false,
    });

    if (document.querySelector(".prev-fotos-sobre")) {
        document.querySelector(".prev-fotos-sobre").onclick = function () {
            sliderFotosSobre.goTo("prev");
        };
    }

    if (document.querySelector(".next-fotos-sobre")) {
        document.querySelector(".next-fotos-sobre").onclick = function () {
            sliderFotosSobre.goTo("next");
        };
    }
}

if (document.querySelector(".slide-wrapper-fotos-conteudo")) {
    var sliderfotosconteudo = tns({
        container: ".slide-wrapper-fotos-conteudo",
        responsive: {
            0: {
                items: 2,
            },
            992: {
                items: 3,
            },
        },
        autoplay: false,
        axis: "vertical",
        mouseDrag: true,
        loop: true,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        nav: false,
        autoplayButton: false,
        controls: false,
    });

    if (document.querySelector(".prev-fotos-conteudo")) {
        document.querySelector(".prev-fotos-conteudo").onclick = function () {
            var limitefotos = parseInt($(".foto-ativa").data("qtd-fotos") - 1);
            var fotoatual = parseInt($(".foto-ativa").data("foto-ativa"));

            if (fotoatual - 1 < 0) {
                fotoatual = limitefotos;
            } else {
                fotoatual = fotoatual - 1;
            }

            $(".foto-ativa").addClass("d-none");
            $(".foto-ativa").removeClass("foto-ativa");

            $("[data-foto-ativa=" + fotoatual + "]").addClass("foto-ativa");
            $("[data-foto-ativa=" + fotoatual + "]").removeClass("d-none");

            sliderfotosconteudo.goTo("prev");
        };
    }

    if (document.querySelector(".next-fotos-conteudo")) {
        document.querySelector(".next-fotos-conteudo").onclick = function () {
            var limitefotos = parseInt($(".foto-ativa").data("qtd-fotos") - 1);
            var fotoatual = parseInt($(".foto-ativa").data("foto-ativa"));

            if (fotoatual + 1 > limitefotos) {
                fotoatual = 0;
            } else {
                fotoatual = fotoatual + 1;
            }

            $(".foto-ativa").addClass("d-none");
            $(".foto-ativa").removeClass("foto-ativa");

            $("[data-foto-ativa=" + fotoatual + "]").addClass("foto-ativa");
            $("[data-foto-ativa=" + fotoatual + "]").removeClass("d-none");

            sliderfotosconteudo.goTo("next");
        };
    }
}


if (document.querySelector('.slide-wrapper-fotos-padrao')) {

    var sliderfotospadrao = tns({
        container: ".slide-wrapper-fotos-padrao",
        items: 1,
        autoplay: true,
        autoplayTimeout: 15000,
        mouseDrag: true,
        loop: false,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        nav: false,
        autoplayButton: false,
        controls: false
    });

    if (document.querySelector('.prev-fotos-padrao')) {
        document.querySelector('.prev-fotos-padrao').onclick = function () {
            sliderfotospadrao.goTo('prev');
        };
    }

    if (document.querySelector('.next-fotos-padrao')) {
        document.querySelector('.next-fotos-padrao').onclick = function () {
            sliderfotospadrao.goTo('next');
        };
    }
}
